<template>

    
<b-modal
        ref="modalBanner"
        centered
        hide-footer
        size="sm"
        title="Cambiar banner del negocio"
        @hidden="closeModalFotoBanner"
      >
        <div class="d-block text-center">
  
          <b-form
            autocomplete="off"
            @submit.prevent="updateBannerProfile"
          >
  
            <b-row>

              <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom: 10px;"
            >
            
          <b-alert
                variant="primary"
                show
                
                >
                <div class="alert-body">
                        
                  <p style="font-size:12px;text-align: center;"> Su imagen debe pesar menos de 3mb y debe tener un tamaño con aspecto 16:9, es decir, debe ser más ancho que largo.</p>


                </div>
                </b-alert>
                
                </b-col>
  
              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
                style="margin-bottom: 10px;"
              >
  
        
                <b-form-file
                  id="localBanner"
                  v-model="localBanner"
                  required
                  :disabled="isActiveFoto"
                  accept="image/jpeg, image/jpg"
                  placeholder="Seleccione una imagen o suéltelo aquí..."
                  drop-placeholder="Suelte la imagen aqui..."
                  @change="previewImage2"
                />
  
                <p style="margin: 0;font-size: 12px;display:flex">{{ uploadValue1.toFixed()+"%" }}
                  <progress
                    style="width:100%"
                    :value="uploadValue1"
                    max="100"
                  />  </p>
  
              </b-col>
  
              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
  
                <p
                  class="textLabel"
                  style="text-align: center;margin-bottom: 10px;"
                >
                  Ingrese su código PIN (*): <feather-icon
                                    icon="HelpCircleIcon"
                                    size="16"
                                      v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                    title="Código PIN de seguridad"
                                    style="margin-right: 5px;cursor:pointer;"
                                    /> </p>
  
                <PincodeInput
                  v-model="pinBanner"
                  placeholder="-"
                  :length="6"
                  :autofocus="false"
                  :secure="true"
                  :disabled="isActiveFoto"
                  required
                />
  
              </b-col>
  
              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
  
                <b-button
                  id="btn_banner"
                  ref="btn_banner"
                  type="submit"
                  variant="primary"
                  block
                  :disabled="isActiveFoto"
                >
  
                  Cambiar banner del negocio
  
                </b-button>
  
              </b-col>
  
            </b-row>
          </b-form>
  
        </div>
  
      </b-modal>
          
          </template>
          
          <script>
          import {
            BButton, BRow, VBPopover, BCol, BCard,BFormFile, BForm,BAlert
          } from 'bootstrap-vue'
          import PincodeInput from 'vue-pincode-input'
          import 'firebase/compat/storage'
          
          export default {
            components: {
                BForm,
                BAlert,
                BFormFile,
              BButton,
              BRow,
              BCol,
              BCard,
              PincodeInput
            },
            directives: {
              'b-popover': VBPopover,
            },
            props: ['idLocal', 'tokenAuth', 'userId'],
            data() {
          
          
              return {
                localBanner: [],
                uploadValue1: 0,
                isActiveFoto: false,
                pinBanner: '',
                imageData2: null,
              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {
    
                this.pinBanner = ''
                
                this.isActiveFoto = false

                this.imageData2 = null

                this.uploadValue1 = 0

                this.localBanner = []

                this.$refs.modalBanner.show()
          
            },
            methods: {
    
             
    
                closeModalFotoBanner(){
                    this.$eventBus.$emit('reiniciarModalBanner')
                },

                
  
      updateBannerProfile(event) {
        event.preventDefault()
  
        this.isActiveFoto = true
        this.uploadValue1 = 0
  
        const d1 = new Date();

        let nameFile= this.userId+"_"+d1.getTime()+"_"+this.localBanner.name;
        let dir= 'negociosBanner/'+this.userId;

        const storageRef = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).put(this.localBanner);

      
        storageRef.on('state_changed', snapshot => {
          this.uploadValue1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  
          document.getElementById('btn_banner').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Subiendo foto'
        }, error => {
          this.$toast.error("Ocurrió un error inesperado".message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
  
          this.isActiveFoto = false
          document.getElementById('btn_banner').innerHTML = 'Cambiar banner de perfil'
        },
        () => {
          this.uploadValue1 = 100
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            const data_json = { userId: this.userId, url, pin: this.pinBanner,  id: this.idLocal }
            const data_string = JSON.stringify(data_json)
  
            const dataEncripted = this.$encryptBackoffice.encrypt(data_string)
  
            document.getElementById('btn_banner').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando banner'
  
            this.$https.post('/locals/updateBannerLocal/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
              if (response.data.code == 200) {
                this.pinBanner = ''
                this.localBanner = []
                this.isActiveFoto = false
  
             
               
                  this.$toast.success(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  });

                  this.$eventBus.$emit('updateBannerNegocio', url);
                  this.$eventBus.$emit('reiniciarPerfilCompletado2', false)
            this.$eventBus.$emit('reiniciarPerfilCompletado')
  
                document.getElementById('btn_banner').innerHTML = 'Cambiar banner del negocio'
                this.$refs.modalBanner.hide()
              } else {
                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
  
                if (response.data.code == 401) {
                  this.$refs.modalBanner.hide()
  
                  
                  localStorage.removeItem('userData')
  

  
                  // Redirect to login page
                  this.$router.push({ name: 'auth-login' })
                }
              }
            }).catch(error => {
              this.$toast.error("Ocurrió un error inesperado", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            })
          })
        })
      },
  
      
  
      previewImage2(event) {
        const FileSizeBanner = event.target.files[0].size / 1024 / 1024
        let foto=event.target.files[0];
        if (FileSizeBanner > 3) {
          this.localBanner = [];
  
          this.$toast.error('La imagen debe pesar menos de 3mb', {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        }else{
              

              let imgwidth=0;
              let imgheight=0;
 

                      var _URL = window.URL || window.webkitURL;
                    
                      var img = new Image();
                    
                      img.onload = () => {
                          // here you got the width and height
                          imgwidth = img.width;
                        imgheight = img.height;

                        var ratio = imgwidth / imgheight;
                        let calculo=( Math.abs( ratio - 4 / 3 ) < Math.abs( ratio - 16 / 9 ) ) ? '4:3' : '16:9';


                        if(calculo != "16:9"){
                          this.localBanner = [];
                          this.$toast.error('Su banner debe tener un tamaño con aspecto 16:9, es decir, debe ser más ancho que largo.', {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                          })

                        }


                };
                img.src = _URL.createObjectURL(foto);



      }
      },
  
    
            }
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          